<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <!-- <h2 class="brand-text text-primary ml-1">
          indiquei.vc
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <!-- <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Bem-vindo ao indiquei.vc! 👋
          </b-card-title> -->
          <!-- <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text> -->

          <!-- <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Esqueceu a senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="senha"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Senha"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || waitingLoading"
              >
                Entrar
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Login-->

    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import jwtDecode from 'jwt-decode'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BFormCheckbox,
    // BCardText,
    // BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      modal: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      waitingLoading: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    localize('pt_BR')
  },
  methods: {
    loginWithGoogle() {
      this.$gAuth
        .getAuthCode()
        // .then(authCode => {
        //   // on success
        // })
        // .catch(error => {
        //   // on fail do something
        // })
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        this.waitingLoading = true
        if (success) {
          useJwt.login({
            login: this.userEmail,
            password: this.password,
            companyToken: '',
          })
            .then(response => {
              if (!response.data.httpResponse.isSuccessStatusCode) {
                this.waitingLoading = false
                return this.$refs.loginForm.setErrors({
                  email: response.data.messages,
                  password: [''],
                })
              }
              const userData = jwtDecode(response.data.objectReturn.accessToken)
              userData.role = 'admin'
              // userData.avatar = 'https://lh3.googleusercontent.com/a-/AOh14GjZoNyJFj8Q0AarPDWKyRU0_sghIiZEsNcENiFQtYg=s96-c'

              // recuperando permissões do jwt e aplicando no ability
              const abilityString = jwtDecode(response.data.objectReturn.accessToken).Access
              const parseAbility = JSON.parse(abilityString)
              userData.ability = parseAbility

              // userData.ability = [
              //   {
              //     action: 'read',
              //     subject: 'conversionDashboard',
              //   },
              //   {
              //     action: 'manage',
              //     subject: 'all',
              //   },
              // ]

              // adicionando permissão para páginas comuns a todos os usuários logados
              userData.ability.push({ action: 'edit', subject: 'personalData' })

              useJwt.setToken(response.data.objectReturn.accessToken)
              useJwt.setRefreshToken(response.data.objectReturn.accessToken)
              localStorage.setItem('userData', JSON.stringify(userData))

              this.$ability.update(userData.ability)
              const isSuperAdmin = userData.ability.find(ability => ability.action === 'manage' && ability.subject === 'all')

              return this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Olá ${userData.FullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Você fez o login com sucesso.',
                    },
                  })

                  // verificando se é superAdmin e se é primeiro acesso...
                  if (isSuperAdmin !== undefined && userData.FirstAccess === 'True') {
                    store.commit('api/TOGGLE_MODAL_CONFIG_COMPANY', true)
                  }
                })
            })

          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            .catch(error => {
              this.waitingLoading = false
              this.$refs.loginForm.setErrors(error.response.data.messages[0])
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ocorreu um problema',
                  icon: 'Erro',
                  variant: 'danger',
                  text: error.response.data.messages[0],
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
